<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="profiles?profiles.profile_picture:'img/avatars/profile_img.svg'"
            class="c-avatar-img-h100"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem to="/profile">
      <CIcon name="cil-user"/> Profile
    </CDropdownItem>
    <CDropdownItem v-on:click="getHelp">
      <CIcon name="cil-info"/> Help
    </CDropdownItem>
    <CDropdownItem v-on:click="getLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TheHeaderDropdownAccnt',
  props: {
  },
  data () {
    return { 
      profiles: {
        profile_picture: 'img/avatars/profile_img.svg'
      }
    }
  },
  async created() {
    this.showUser()
  },
  methods: {
    ...mapActions('auth', ['authout']),
    async getLogout() {
      localStorage.removeItem('token')
      localStorage.removeItem('userdata')
      localStorage.removeItem('listalertdsb')
      localStorage.removeItem('listalert')
      await this.authout().then((response) => { })
      setTimeout(() => {
        this.$router.push('/login')
      }, 500);
    },
    showUser() {
      this.profiles = this.$store.state.userdata
      if(this.profiles) {
        this.profiles.profile_picture = this.profiles.profile_picture?this.profiles.profile_picture:'img/avatars/profile_img.svg'
      }
      this.$store.watch(
        (state)=>{
          return this.$store.state.userdata
        },
        (newValue, oldValue)=>{
          if(newValue!=oldValue) {
            if(newValue) {
              this.profiles.profile_picture = newValue.profile_picture?newValue.profile_picture:'img/avatars/profile_img.svg'
            }
          }
        },
        { deep: true }
      )
    },
    async getHelp() {
      window.open('/storage/doc/cirrus_pro.pdf', '_blank')
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>