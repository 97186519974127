<template>
  <CFooter :fixed="false">
    <div>
      IWC2
      <span class="ml-1">&copy; 2021</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      IWC2
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
