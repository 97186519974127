<template>
  <CHeader fixed with-subheader light>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <span>{{ $route.name }}</span>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CDropdown
        inNav
        class="c-header-nav-items mr-3"
        placement="bottom-start"
        add-menu-classes="pt-0 pb-0"
      >
        <template #toggler>
          <CHeaderNavLink>
            <CIcon name="cil-bell"/>
            <span class="badge badge-warning navbar-badge">{{countnotif.length}}</span>
          </CHeaderNavLink>
        </template>
        <CDropdownItem v-for="(notif, idn) in notifications" :key="idn" class="padding-0" @click="showNotif(notif)">
          <CRow class="col-xl-12 margin-0 padding-msg lineh-1">
            <CCol xl="2" class="padding-0">
              <div class="c-avatar">
                <img
                  :src="notif.data.picture?notif.data.picture:(notif.data.type=='message')?'img/avatars/profile_img.svg':'img/avatars/dot-circle.svg'"
                  class="c-avatar-img-h100"
                />
              </div>
            </CCol>
            <CCol xl="10" class="padding-0">
              <CCol xl="12" class="padding-0 text-right"><small class="text-muted w-100">{{formatTimenotif(notif.created_at)}}</small></CCol>
              <CCol xl="12" class="padding-0 text-justify" style="width: 300px;"><span style="white-space: normal;">{{filterLatest(notif.data.message, 50)}}</span></CCol>
            </CCol>
          </CRow>
        </CDropdownItem>
        <CDropdownDivider v-if="notifications.length>0" class="padding-0 mt-2 mb-0" />
        <CDropdownItem class="padding-0" to="/notification">
          <CCol xl="12" class="padding-0 text-center"><small class="text-muted w-100">See All Notifications</small></CCol>
        </CDropdownItem>
      </CDropdown>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  props: {
  },
  created() {
    this.onLoad()
  },
  data () {
    return {
      nav: [],
      isMLoading: false, isNLoading: false,
      notifications: [],
      countnotif: []
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalMessage)
  },
  methods: {
    ...mapActions('role', ['updateARPMS']),
    ...mapActions('notification', ['showunreadNTF', 'readNTF']),
    onLoad() {
      this.intervalMessage = setInterval(this.showMessage, 10000)
    },
    async showMessage(){
      this.isNLoading = true
      this.notifications = []
      this.countnotif = []
      await this.showunreadNTF().then((response) => {
        this.notifications = response.length > 0 ? response.slice(0,5) : []
        this.countnotif = response.length > 0 ? response : []
        this.isNLoading = false
      }).catch((error) => {
        console.log('error ', error)
        this.isNLoading = false
      })
    },
    filterLatest(value, size) {
        if (!value) return '-'
        value = value.toString()
        return value.length > size ? value.slice(0, size) + '...' : value
    },
    formatTimenotif(value) {
        return moment(String(value)).format('DD/MM/YY hh:mm')
    },
    showNotif(val){
      this.readNTF(val.id).then((response) => {
        if(val.data.type=='message') {
          if(this.$route.name=='Chat Group') {
            this.$router.push({name: 'Notification', params: { notifroute: val }})
          } else {
            this.$router.push({name: 'Chat Group', params: { roomid: parseInt(val.data.room_id), roomtype: val.data.room_type }})
          }
        } else {
          if(this.$route.name!='Notification') {
            this.$router.push({name: 'Notification', params: { notifroute: val }})
          } else {
            this.$router.go()
          }
        }
      }).catch((error) => {
        console.log('error ', error)
      })
    }
  }
}
</script>
