<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main padding-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  props: {
  },
  data () {
    return {
      company_name: 'Cirrus Pro',
      generals: [],
    }
  },
  created() {
    this.getInfoWeb()
  },
  methods: {
    ...mapActions('information', ['infoCC']),
    async getInfoWeb() {
      await this.infoCC().then((response) => {
        this.generals = response.length > 0 ? response : []
        var d_cn = this.generals.findIndex(obj => obj.name ==="cc_company_name")
        var companyname = this.generals[d_cn]?this.generals[d_cn].value:this.company_name
        document.title = companyname?companyname:this.company_name
      }).catch((error) => {
        console.log('Error', error)
      })
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
