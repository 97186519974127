<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapActions } from 'vuex'
import nav from './_nav'

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  created() {
    this.getPermission()
  },
  data () {
    return {
      nav: [],
      isMLoading: false
    }
  },
  methods: {
    ...mapActions('role', ['updateRPMS']),
    async getRole(uuid){
      this.isMLoading = true
      await this.updateRPMS(uuid).then((response) => {
        var navs = this.getNavRole(response.role.permissions)
        navs.push('Dashboard', 'Chat & Group', 'Contact', 'Reports', 'Map View', 'License')
        this.nav = this.filterMenu(nav, navs)
        this.isMLoading = false
      }).catch((error) => {
        this.isMLoading = false
      })
    },
    async getPermission() {
        var profile = this.$store.state.userdata
        var role = profile.role ? profile.role.uuid:''
        this.getRole(role)
    },
    filterMenu(nav, filter){
      // var filter = ['Dashboard', 'AWOS', 'RADAR', 'Data Management', 'Chat & Group', 'Contact', 'AWOS Logs', 
      //   'RADAR Logs', 'Reports', 'Config']
      var head = ['CSidebarNavTitle']
      var navs = nav[0]._children.filter(function(item) {
        if(filter.includes(item.name) || head.includes(item._name))
        return true
      })
      return [{ _name: 'CSidebarNav', _children: navs }]
    },
    getNavRole(val) {
      var filter = ['Dashboard Awos', 'Dashboard Radar', 'Data Management', 'Log Awos', 
        'Log Radar', 'Data Users', 'Pengaturan', 'Data Master']
      var filternav = val.filter(function(item) {
        if(filter.includes(item.display_name)) return true
      })
      var permiss = filternav.map(function (obj) {
        if(obj.display_name=='Data Master') {
          return 'Master Data'
        } else if(obj.display_name=='Dashboard Awos') {
          return 'AWOS'
        } else if(obj.display_name=='Log Awos') {
          return 'AWOS Logs'
        } else if(obj.display_name=='Dashboard Radar') {
          return 'RADAR'
        } else if(obj.display_name=='Log Radar') {
          return 'RADAR Logs'
        } else if(obj.display_name=='Data Management') {
          return 'Data Management'
        } else if(obj.display_name=='Data Users') {
          return 'Users'
        } else if(obj.display_name=='Pengaturan') {
          return 'Config'
        }
      })
      return permiss
    },
  }
}
</script>
