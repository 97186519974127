export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['AWOS & Radar Dashboard']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-home'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'AWOS',
        to: '/awos',
        icon: 'cil-compass'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'RADAR',
        to: '/radar',
        icon: 'cil-rss'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Map View',
        to: '/map_view',
        icon: 'cil-globe-alt'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Management']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data Management',
        to: '/data_management',
        icon: 'cil-file'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Forum']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Chat & Group',
        to: '/chat_group',
        icon: 'cil-chat-bubble'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Contact',
        to: '/contact',
        icon: 'cil-contact'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Logs & Reports']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'AWOS Logs',
        to: '/awos_logs',
        icon: 'cil-clipboard'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'RADAR Logs',
        to: '/radar_logs',
        icon: 'cil-clipboard'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reports',
        to: '/reports',
        icon: 'cil-description'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Settings']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        icon: 'cil-group'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Config',
        to: '/config',
        icon: 'cil-cog'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Master Data',
        route: '/division',
        icon: 'cil-storage',
        items: [
          { name: 'Privilege', to: '/role' },
          { name: 'Division', to: '/division' },
          { name: 'Air Base', to: '/airport' },
          { name: 'Unit', to: '/unit' },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'License',
        to: '/license',
        icon: 'cil-notes'
      },
    ]
  }
]